import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo";
import AssociatesNavList from "../components/associates/nav_items";
import College_hero from "../components/associates/colleges/college_hero";
import Business_laboratory from "../components/associates/colleges/business_labrotary";
import Out_comeBl from "../components/associates/colleges/out_comeBL";
import Infrastructure from "../components/associates/colleges/infrastrature";



const College = () => (
  <Layout navName="Associates">
    <Seo title="Colleges" />
    <AssociatesNavList navName="College" />
    <College_hero />
    <Business_laboratory />
    <Out_comeBl />
    <Infrastructure />
  </Layout>
)

export default College
